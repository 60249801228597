<template>
  <div>
    <RegisterQuestion :questionDisplay="questionDisplay" />
    <div class="container">
      <div class="auth-container d-flex flex-column">
        <h3 class="color-primary text-center head-text font-weight-bold">
          สมัครสมาชิก FA Station
        </h3>
        <form @submit.prevent="register">
          <div class="row">
            <div class="col-12">
              <label class="color-primary">อีเมล</label>
              <input
                class="w-100"
                type="email"
                placeholder="อีเมล"
                v-model="email"
                :disabled="regisType !== 'email'"
                required
              />
            </div>
            <div class="col-6">
              <label class="color-primary">ชื่อ</label>
              <input
                class="w-100"
                type="text"
                placeholder="ชื่อ"
                v-model="firstName"
                required
              />
            </div>
            <div class="col-6">
              <label class="color-primary">นามสกุล</label>
              <input
                class="w-100"
                type="text"
                placeholder="นามสกุล"
                v-model="lastName"
                required
              />
            </div>
            <div class="col-6" v-if="regisType === 'email'">
              <label class="color-primary"
                >รหัสผ่าน
                <span style="font-size:10px">(อย่างน้อย 8 ตัว)</span></label
              >
              <div class="position-relative">
                <input
                  class="w-100"
                  :type="passwordDisplay"
                  minlength="8"
                  v-model="password"
                  placeholder="รหัสผ่าน"
                  required
                />
                <img
                  class="password-icon"
                  src="../assets/images/showpassword.svg"
                  alt=""
                  @click="togglePasswordDisplay"
                />
              </div>
            </div>
            <div class="col-6" v-if="regisType === 'email'">
              <label class="color-primary"
                >ยืนยันรหัสผ่าน<span
                  style="font-size:10px; color: red"
                  v-if="confirmPassword && password !== confirmPassword"
                >
                  ไม่ตรงกัน</span
                ></label
              >
              <div class="position-relative">
                <input
                  class="w-100"
                  :type="confirmDisplay"
                  minlength="8"
                  v-model="confirmPassword"
                  placeholder="ยืนยันรหัสผ่าน"
                  required
                />
                <img
                  class="password-icon"
                  src="../assets/images/showpassword.svg"
                  alt=""
                  @click="toggleConfirmDisplay"
                />
              </div>
            </div>
            <div class="col-12">
              <label class="color-primary"
                >หน่วยงาน(ถ้ามี)
                <img
                  v-b-tooltip.hover
                  title="คำอธิบายประเภท"
                  src="../assets/images/info.svg"
                  alt=""
              /></label>
              <div class="position-relative">
                <select class="d-block w-100" v-model="organizationId" required>
                  <option
                    v-for="organization in organizationList"
                    :key="organization.organization_id"
                    :value="organization.organization_id"
                    style="color: black;"
                    >{{ organization.name }}</option
                  >
                </select>
                <img
                  class="dropdown-icon"
                  src="../assets/images/dropdown-icon.svg"
                  alt=""
                />
              </div>
            </div>
            <div class="col-12">
              <label class="color-primary"
                >รหัสอ้างอิงสังกัดหรือบุคคล (ถ้ามี)
                <img
                  v-b-tooltip.hover
                  title="คำอธิบายรหัส"
                  src="../assets/images/info.svg"
                  alt=""
              /></label>
              <input
                class="w-100"
                type="text"
                placeholder="Ref. code"
                v-model="refCode"
              />
            </div>
          </div>
          <div class="d-flex align-items-center">
            <CustomCheckbox
              class="mx-sm-n5 my-4"
              :checked="acceptTerm"
              @toggleCheck="toggleAccept"
            >
              <p class="mb-0">
                ยอมรับ
                <span class="color-secondary" style=" cursor: pointer;">
                  <router-link
                    class="color-secondary"
                    :to="{ name: 'Terms' }"
                    target="_blank"
                    ><u>ข้อกำหนดและเงื่อนไข</u></router-link
                  >
                </span>
                และ
                <span class="color-secondary" style="cursor: pointer;">
                  <router-link
                    class="color-secondary"
                    :to="{ name: 'Privacy' }"
                    target="_blank"
                    ><u>นโยบายความเป็นส่วนตัว</u></router-link
                  >
                </span>
                ของ Fastation
              </p>
            </CustomCheckbox>
          </div>
          <button class="secondary w-100" :disabled="!acceptTerm">
            <p class="text-big">สมัครสมาชิก</p>
          </button>
        </form>
        <p class="text-big text-line color-primary">
          <span>หรือ</span>
        </p>
        <button
          class="gg-button position-relative"
          type="button"
          @click="socialGoogleLogin"
        >
          <img class="position-absolute" src="../assets/images/gg.svg" alt="" />
          <p class="text-big">สมัครสมาชิกด้วย Google</p>
        </button>
        <button
          class="fb-button position-relative"
          type="button"
          @click="socialFacebookLogin"
        >
          <img class="position-absolute" src="../assets/images/fb.svg" alt="" />
          <p class="text-big">สมัครสมาชิกด้วย Facebook</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import axios from "../api/faAPI";
import baseUrl from "../util/backend";
import RegisterQuestion from "../components/RegisterQuestion.vue";
import CustomCheckbox from "../components/CustomCheckbox.vue";
export default {
  components: { RegisterQuestion, CustomCheckbox },
  data() {
    return {
      organizationList: [],
      passwordDisplay: "password",
      confirmDisplay: "password",
      regisType: "email",
      // --- user info --- //
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      category: 1,
      organizationId: null,
      refCode: "",
      acceptTerm: false,
      accessToken: null,
      idToken: null,
      // <-- user info --> //
      questionDisplay: false,
    };
  },
  methods: {
    togglePasswordDisplay() {
      if (this.passwordDisplay === "password") {
        this.passwordDisplay = "text";
      } else {
        this.passwordDisplay = "password";
      }
    },
    toggleConfirmDisplay() {
      if (this.confirmDisplay === "password") {
        this.confirmDisplay = "text";
      } else {
        this.confirmDisplay = "password";
      }
    },
    toggleAccept(value) {
      this.acceptTerm = value;
    },

    async socialGoogleLogin() {
      const provide = new firebase.auth.GoogleAuthProvider();
      provide.addScope("email");
      provide.addScope("profile");
      try {
        const result = await firebase.auth().signInWithPopup(provide);
        console.log(result);
        this.email = result.additionalUserInfo.profile.email;
        this.accessToken = result.credential.accessToken;
        this.idToken = result.credential.idToken;
        // this.name = result.additionalUserInfo.profile.given_name;
        // this.surname = result.additionalUserInfo.profile.family_name || "";
        this.regisType = "google";
        // this.$store.dispatch("setSocialRegister", obj);
      } catch (err) {
        alert("Oops. " + err.message);
      }
    },
    async socialFacebookLogin() {
      const provide = new firebase.auth.FacebookAuthProvider();
      provide.addScope("email");
      provide.addScope("public_profile");
      try {
        const result = await firebase.auth().signInWithPopup(provide);
        console.log(result);
        this.email = result.additionalUserInfo.profile.email;
        this.accessToken = result.credential.accessToken;
        // this.name = result.additionalUserInfo.profile.first_name,
        // this.surname = result.additionalUserInfo.profile.last_name, || "";
        this.regisType = "facebook";
      } catch (err) {
        alert("Oops. " + err.message);
      }
    },
    async register() {
      this.$store.dispatch("setLoading", true);
      if (this.regisType === "email") {
        if (this.password !== this.confirmPassword) {
          alert("รหัสผ่านไม่ตรงกัน");
          this.$store.dispatch("setLoading", false);
          return;
        }
        let userDetail = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          password: this.password,
          category: this.category,
          organization_id: this.organizationId,
          type_login: this.regisType,
          ref_code: this.refCode,
          consent: this.acceptTerm,
        };
        try {
          await axios.post(`${baseUrl}/register`, userDetail);
          await this.$store.dispatch("login", {
            email: this.email,
            password: this.password,
          });
          // this.$router.push({ name: "Home" });
          this.questionDisplay = true;
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      }
      if (this.regisType === "google") {
        console.log("gg regis");
        let userDetail = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          category: this.category,
          organization_id: this.organizationId,
          type_login: this.regisType,
          ref_code: this.refCode,
          accessToken: this.accessToken,
          idToken: this.idToken,
          consent: this.acceptTerm,
        };
        console.log(userDetail);
        try {
          await axios.post(`${baseUrl}/register`, userDetail);
          await this.$store.dispatch("firebaseLogin", {
            token: this.idToken,
            type: "google",
          });
          // this.$router.push({ name: "Home" });
          this.questionDisplay = true;
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      }
      if (this.regisType === "facebook") {
        console.log("fb regis");
        let userDetail = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          category: this.category,
          organization_id: this.organizationId,
          type_login: this.regisType,
          ref_code: this.refCode,
          accessToken: this.accessToken,
          consent: this.acceptTerm,
        };
        console.log(userDetail);
        try {
          await axios.post(`${baseUrl}/register`, userDetail);
          await this.$store.dispatch("firebaseLogin", {
            token: this.accessToken,
            type: "facebook",
          });
          // this.$router.push({ name: "Home" });
          this.questionDisplay = true;
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      }
      this.$store.dispatch("setLoading", false);
    },
    async loadOrganizationList() {
      const res = await axios.get(`${baseUrl}/getUserOrganization`);
      console.log(res.data.data);
      this.organizationList = res.data.data;
      this.organizationId = res.data.data[0].organization_id;
    },
  },
  created() {
    this.loadOrganizationList();
    if (this.$route.query.refCode) {
      this.refCode = this.$route.query.refCode;
    }
  },
};
</script>

<style scoped>
input:focus,
select:focus {
  outline: none;
}

input,
select {
  border: 1px solid #0430ad;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;

}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  background-color: transparent;
  color: #000;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: -1;
}

.password-icon {
  position: absolute;
  cursor: pointer;
  top: calc(50% - 5px);
  right: 10px;
  transform: translateY(-50%);
}

button {
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
}

button.primary {
  background-color: #0430ad;
}

button.secondary {
  background-color: #07c5f8;
}

.auth-container {
  margin: 100px auto;
  max-width: 370px;
}

.head-text {
  margin-bottom: 50px;
}

.text-line {
  width: 100%;
  max-width: 400px;
  text-align: center;
  border-bottom: 3px solid #0430ad;
  line-height: 0.1em;
  margin: 40px auto;
}

.text-line span {
  background: #fff;
  padding: 0 10px;
}

::-webkit-input-placeholder {
  /* text-align: center; */
  color: #798da5;
  font-size: 16px;
}

:-moz-placeholder {
  /* Firefox 18- */
  /* text-align: center; */
  color: #798da5;
  font-size: 16px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  /* text-align: center; */
  color: #798da5;
  font-size: 16px;
}

:-ms-input-placeholder {
  /* text-align: center; */
  color: #798da5;
  font-size: 16px;
}

.gg-button {
  border: 1px solid #0430ad;
  color: #0430ad;
  background-color: #fff;
}

.gg-button img {
  left: 10px;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.fb-button {
  border: 1px solid #405793;
  color: #405793;
  background-color: #fff;
}

.fb-button img {
  left: 10px;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
